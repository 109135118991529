// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-width: 1000px;
  width: 100%;
  text-align: center;
  position: relative;
}

a{
  text-decoration: none;
}


/* animation: start .4s ease-out;

@keyframes start {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;;AAGA;;;;;;;;;;GAUG","sourcesContent":[".App {\n  min-width: 1000px;\n  width: 100%;\n  text-align: center;\n  position: relative;\n}\n\na{\n  text-decoration: none;\n}\n\n\n/* animation: start .4s ease-out;\n\n@keyframes start {\n  from {\n      opacity: 0;\n  }\n\n  to {\n      opacity: 1;\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
